import { FC, ReactNode, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { EMPTY_VALUE } from 'utils/constants';
import { useTranslations } from 'components/shared/i18n';
import { useFormContext } from 'react-hook-form';
import { Checkbox, IconButton, ListItemIcon, ListItemText, MenuItem, Radio, Switch, TextField } from '@mui/material';
import { Body1, ehiTheme, Subtitle1 } from '@ehi/ui';
import { Check, Search } from '@mui/icons-material';
import {
  ButtonContainer,
  EhiButtonReset,
  HeaderContainer,
  HeaderTitle,
  SelectMenuFooter,
  SelectMenuHeader,
  SelectMenuSubHeader,
  StyledFormTextField,
  SubHeaderTitle,
} from 'components/shared/forms/Forms.styles';
import { OptionItem, SelectDropDownProps, SelectionVariant } from 'components/shared/forms/FormFieldTypes';
import CloseIcon from '@mui/icons-material/Close';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { parseUrn } from 'utils/urnUtils';

type MenuState = {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
};

export const SelectMenu: FC<SelectDropDownProps> = ({
  formFieldName,
  label,
  primaryList,
  secondaryList,
  selectionVariant = SelectionVariant.NONE,
  defaultSelection,
  required = false,
  allToggleLabel,
  inputAllText,
  searchFieldPlaceholder,
  onClose,
  footerText,
  textColor,
  showPillInput = false,
  autoFocus = false,
}: SelectDropDownProps) => {
  const [searchTerm, setSearchTerm] = useState<string>(EMPTY_VALUE);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [menuState, setMenuState] = useState<MenuState>({ isOpen: false, anchorEl: null });

  const { t } = useTranslations();
  const { watch, setValue, resetField } = useFormContext();
  const selectedItems: string[] | string = watch(formFieldName);
  const isMultiSelectDropdown = Array.isArray(selectedItems);

  const fullOptionList: OptionItem[] = useMemo(() => {
    return secondaryList ? [...primaryList.list, ...secondaryList.list] : [...primaryList.list];
  }, [primaryList.list, secondaryList]);
  const allOptionsSelected = useMemo(() => {
    return fullOptionList && Array.isArray(selectedItems) && fullOptionList.length === selectedItems.length;
  }, [fullOptionList, selectedItems]);

  const toggleAllOptions = useCallback(() => {
    if (defaultSelection) {
      allOptionsSelected
        ? resetField(formFieldName)
        : setValue(
            formFieldName,
            primaryList.list.map((item) => item.id)
          );
    } else {
      allOptionsSelected ? setValue(formFieldName, []) : resetField(formFieldName);
    }
  }, [allOptionsSelected, setValue, defaultSelection, resetField, formFieldName, primaryList.list]);

  const handleReset = useCallback(() => {
    setSearchTerm(EMPTY_VALUE);
    resetField(formFieldName);
  }, [formFieldName, resetField]);

  const handleOpen = useCallback((event: SyntheticEvent<Element, Event>) => {
    setMenuState({ isOpen: true, anchorEl: event.currentTarget as HTMLElement });
  }, []);

  const handleClose = useCallback(() => {
    setSearchTerm(EMPTY_VALUE);
    setMenuState({ isOpen: false, anchorEl: menuState.anchorEl });
    onClose && onClose();
  }, [menuState.anchorEl, onClose]);

  const renderSelectedOptions = useCallback(() => {
    if (!isMultiSelectDropdown) {
      const selectedOption = fullOptionList?.find((option) => selectedItems && selectedItems.includes(option.id));
      return selectedOption ? selectedOption.label : EMPTY_VALUE;
    } else {
      if (selectedItems.length === 0 || allOptionsSelected) {
        return inputAllText ? inputAllText : t('common.all');
      } else {
        const label = fullOptionList.find((option) => option.id === selectedItems[0])?.label;
        return selectedItems.length === 1 ? label : `${label?.substring(0, 15)}..., +${selectedItems?.length - 1}`;
      }
    }
  }, [isMultiSelectDropdown, fullOptionList, selectedItems, allOptionsSelected, inputAllText, t]);

  const SearchIcon = (): ReactNode => {
    return <Search color={'secondary'} style={{ padding: ehiTheme.spacing(1) }} />;
  };

  const getSelectedValues = useCallback(() => {
    if (!isMultiSelectDropdown && (selectedItems === EMPTY_VALUE || !selectedItems?.includes(searchTerm))) {
      return EMPTY_VALUE;
    }

    return selectedItems;
  }, [isMultiSelectDropdown, searchTerm, selectedItems]);

  const setHeaderRefHeight = useCallback(
    (ref: HTMLDivElement | null) => {
      if (
        ref?.offsetHeight &&
        headerHeight !== ref?.offsetHeight &&
        (primaryList.subHeader || secondaryList?.subHeader)
      ) {
        setHeaderHeight(ref?.offsetHeight);
      }
    },
    [headerHeight, primaryList.subHeader, secondaryList?.subHeader, setHeaderHeight]
  );

  const getOptionListView = useCallback(
    (optionList: OptionItem[]) => {
      return optionList
        .filter((option) => searchTerm.length === 0 || option.label?.toLowerCase().includes(searchTerm.toLowerCase()))
        .map((option) => {
          const isOptionSelected = isMultiSelectDropdown
            ? selectedItems.indexOf(option?.id) > -1
            : selectedItems === option.id;
          return (
            <MenuItem
              key={option.id}
              value={option.id}
              data-testid={`option-${option.id.includes('urn') ? parseUrn(option.id) : option.id}`}
              style={{ padding: ehiTheme.spacing(1.5, 2), color: textColor }}>
              {selectionVariant === SelectionVariant.CHECKBOX && (
                <ListItemIcon style={{ justifyContent: 'flex-start' }}>
                  <Checkbox checked={isOptionSelected} style={{ paddingLeft: 0 }} />
                </ListItemIcon>
              )}
              {selectionVariant === SelectionVariant.RADIO && (
                <ListItemIcon style={{ justifyContent: 'flex-start' }}>
                  <Radio checked={isOptionSelected} style={{ paddingLeft: 0 }} />
                </ListItemIcon>
              )}
              <ListItemText
                disableTypography
                primary={
                  <Subtitle1
                    style={{
                      whiteSpace: 'normal',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      paddingRight: ehiTheme.spacing(1),
                    }}>
                    {!isMultiSelectDropdown && defaultSelection === option.id
                      ? `${option.label} (${t('common.default')})`
                      : option.label}
                  </Subtitle1>
                }
              />
              {selectionVariant === SelectionVariant.CHECKMARK && isOptionSelected && (
                <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                  <Check color={'action'} data-testid={'checkmark'} />
                </ListItemIcon>
              )}
            </MenuItem>
          );
        });
    },
    [defaultSelection, isMultiSelectDropdown, searchTerm, selectedItems, selectionVariant, t, textColor]
  );

  return (
    <StyledFormTextField
      name={formFieldName}
      data-testid={`${formFieldName}SelectMenu`}
      label={required ? `${label}*` : label}
      showPillInput={showPillInput}
      autoFocus={autoFocus}
      InputLabelProps={{ shrink: !!allToggleLabel || (!isMultiSelectDropdown && selectedItems.length > 0) }}
      select
      sx={{
        '& fieldset': {
          borderRadius: showPillInput ? 33 : 0,
        },
        '&& .Mui-focused': {
          color: '#000000',
        },
      }}
      SelectProps={{
        open: menuState.isOpen,
        multiple: isMultiSelectDropdown,
        displayEmpty: true,
        notched: !!allToggleLabel || (!isMultiSelectDropdown && selectedItems.length > 0),
        value: getSelectedValues(),
        renderValue: () => renderSelectedOptions(),
        MenuProps: {
          variant: 'menu',
          disableAutoFocusItem: true,
          autoFocus: false,
          marginThreshold: null,
          disableScrollLock: true,
          anchorEl: menuState.anchorEl,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: {
            '&& .Mui-selected': {
              backgroundColor: selectionVariant !== SelectionVariant.NONE ? '#ffffff' : undefined,
            },
            transition: 'none !important',
          },
          slotProps: {
            paper: {
              style: {
                width: showPillInput ? 'auto' : 300,
                minWidth: showPillInput ? 190 : 300,
                maxHeight: window.innerHeight - (menuState.anchorEl?.getBoundingClientRect().bottom ?? 0),
              },
            },
            root: { sx: { '.MuiList-root': { padding: 0 } } },
          },
        },
        onClose: handleClose,
        onOpen: handleOpen,
      }}>
      <SelectMenuHeader ref={setHeaderRefHeight} data-testid='headerContainer'>
        <HeaderContainer>
          <HeaderTitle>{label}</HeaderTitle>
          <IconButton size='small' onClick={handleClose}>
            <CloseIcon color={'secondary'} data-testid='closeButton' />
          </IconButton>
        </HeaderContainer>
        {searchFieldPlaceholder && (
          <HeaderContainer>
            <TextField
              value={searchTerm}
              placeholder={searchFieldPlaceholder}
              data-testid='searchField'
              fullWidth
              type={'text'}
              autoFocus
              InputProps={{
                startAdornment: SearchIcon(),
              }}
              onChange={(e): void => setSearchTerm(e.target.value)}
              onKeyDown={(e): void => e.stopPropagation()}
            />
          </HeaderContainer>
        )}
        {allToggleLabel && (
          <HeaderContainer>
            <Body1 color={'#000000de'}>{allToggleLabel}</Body1>
            <Switch
              checked={allOptionsSelected}
              onChange={toggleAllOptions}
              data-testid='allOptionsSwitch'
              edge={'end'}
            />
          </HeaderContainer>
        )}
      </SelectMenuHeader>
      {primaryList.subHeader && (
        <SelectMenuSubHeader style={{ top: headerHeight }}>
          <SubHeaderTitle data-testid='primarySubHeader'>{primaryList.subHeader}</SubHeaderTitle>
        </SelectMenuSubHeader>
      )}
      {getOptionListView(primaryList.list)}
      {secondaryList?.list && <EhiDivider />}
      {secondaryList?.subHeader && (
        <SelectMenuSubHeader style={{ top: headerHeight }}>
          <SubHeaderTitle data-testid='secondarySubHeader'>{secondaryList.subHeader}</SubHeaderTitle>
        </SelectMenuSubHeader>
      )}
      {secondaryList?.list && getOptionListView(secondaryList.list)}
      {footerText && (
        <SelectMenuFooter>
          <ButtonContainer>
            <EhiButtonReset
              onClick={handleReset}
              data-testid='resetButton'
              sx={{ margin: `${ehiTheme.spacing(0.5, 0.5, 0, 0)}` }}>
              {footerText}
            </EhiButtonReset>
          </ButtonContainer>
        </SelectMenuFooter>
      )}
    </StyledFormTextField>
  );
};
