import { useCallback } from 'react';
import { WhenAndWhereFields, WhenAndWhereValues } from 'components/flexFlow/whenAndWhere/WhenAndWhereTypes';
import { updatePickupInformation, updateReturnInformation } from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectVehicleClassSelection } from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { useTranslations } from 'components/shared/i18n';
import { useBookingIssue } from 'services/booking/useBookingIssue';
import { getAllVehicleNotAvailableBookingIssues, hasVehicleAvailabilityBookingIssues } from 'utils/bookingUtils';

export type SavePickupReturn = {
  save: (data: WhenAndWhereValues) => Promise<ServiceResultType>;
  handleErrors: (errors: ResponseMessage[]) => void;
};

export const useSaveWhenAndWhere = (): SavePickupReturn => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const vehicle = useAppSelector(selectVehicleClassSelection);
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { handleRemoveVehicle } = useBookingIssue();

  const handleErrors = useCallback(
    async (errors: ResponseMessage[]) => {
      await showAlert({ responseMessages: errors });
    },
    [showAlert]
  );

  const save = useCallback(
    async (values: WhenAndWhereValues) => {
      const pickUpLocation = values[WhenAndWhereFields.StartLocationUrn];
      const returnLocation = values[WhenAndWhereFields.ReturnLocationUrn];
      const startDateTime = values[WhenAndWhereFields.StartDateTime];
      const returnDateTime = values[WhenAndWhereFields.ReturnDateTime];

      let pickUpInfo = {
        branch: pickUpLocation,
      };
      pickUpInfo = { ...pickUpInfo, ...(startDateTime ? { dateTime: startDateTime } : {}) };

      let returnInfo = {
        branch: returnLocation,
      };
      returnInfo = { ...returnInfo, ...(returnDateTime ? { dateTime: returnDateTime } : {}) };

      const { errors, data } = await updateAndRefresh(
        async () => {
          await updatePickupInformation(bookingEditorId, pickUpInfo);

          await updateReturnInformation(bookingEditorId, returnInfo);
        },
        {
          availableBookingIssues: [
            ...getAllVehicleNotAvailableBookingIssues(t, t('snackbarMessages.additionalInfo.date'), vehicle),
          ],
        }
      );

      if (errors) {
        await handleErrors(errors);
      } else if (data?.issue && hasVehicleAvailabilityBookingIssues(data.issue)) {
        await handleRemoveVehicle(data.issue);
      }
      return { success: !errors, errors: errors };
    },
    [bookingEditorId, handleErrors, handleRemoveVehicle, t, updateAndRefresh, vehicle]
  );

  return { save, handleErrors };
};
