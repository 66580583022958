import type { Marker } from '@googlemaps/markerclusterer';
import { FC, ReactElement, useCallback } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { CardImageContainer, CardIndexBox, CardIndexTypography } from './BranchCard.styles';
import BranchLookupCardDarkIcon from 'images/branchLookupCardDarkIcon.svg';

export type BranchLocation = {
  key: number;
  stationId?: string;
  position?: google.maps.LatLngLiteral;
};

export type BranchLocationMarkerProps = {
  branch: BranchLocation;
  onClick: (BranchLocation: BranchLocation) => void;
  setMarkerRef: (marker: Marker | null, key: number) => void;
};

/**
 * Wrapper Component for an AdvancedMarker for a single BranchLocation.
 */

const markerIcon = (key: number): ReactElement => (
  <CardImageContainer>
    <img
      data-testid='branchIcon'
      src={BranchLookupCardDarkIcon}
      alt='Branch Icon'
      style={{ width: '75%', height: '75%' }}
    />
    <CardIndexBox>
      <CardIndexTypography variant='caption' selected={false}>
        {key}
      </CardIndexTypography>
    </CardIndexBox>
  </CardImageContainer>
);

export const BranchLookupMarker: FC<BranchLocationMarkerProps> = (props) => {
  const { branch, onClick, setMarkerRef } = props;

  const handleClick = useCallback(() => onClick(branch), [onClick, branch]);
  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) => setMarkerRef(marker, branch.key),
    [setMarkerRef, branch.key]
  );

  return (
    <AdvancedMarker position={branch.position} ref={ref} onClick={handleClick}>
      {markerIcon(branch.key)}
    </AdvancedMarker>
  );
};
